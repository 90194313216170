import { render, staticRenderFns } from "./acta-my-lib.vue?vue&type=template&id=56431819"
import script from "./acta-my-lib.vue?vue&type=script&lang=ts"
export * from "./acta-my-lib.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/atlassian/pipelines/agent/build/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('56431819')) {
      api.createRecord('56431819', component.options)
    } else {
      api.reload('56431819', component.options)
    }
    module.hot.accept("./acta-my-lib.vue?vue&type=template&id=56431819", function () {
      api.rerender('56431819', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/acta-my-lib.vue"
export default component.exports