var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "section",
    {
      ref: "appMainPage",
      staticClass: "app-main",
      on: {
        contextmenu: function ($event) {
          return _vm.showContextMenu($event)
        },
      },
    },
    [
      _c(
        "transition",
        { attrs: { name: "fade-transform", mode: "out-in" } },
        [_c("router-view", { key: _vm.key })],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }