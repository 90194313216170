var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("header", { staticClass: "app-header" }, [
    _c(
      "div",
      { staticClass: "topbar" },
      [
        _c("app-hamburger", {
          staticClass: "hamburger-container",
          attrs: { id: "hamburger-container", "is-active": _vm.sidebar.opened },
          on: { toggleClick: _vm.toggleSideBar },
        }),
        _c(
          "el-menu",
          { staticClass: "el-menu-header", attrs: { mode: "horizontal" } },
          [
            _vm.appsList.length
              ? _c(
                  "el-submenu",
                  { attrs: { index: "main_1" } },
                  [
                    _c("template", { slot: "title" }, [
                      _c("i", { staticClass: "el-icon-s-grid" }),
                      _c("span", [_vm._v(_vm._s(_vm.currentApp?.name))]),
                    ]),
                    _vm._l(_vm.appsList, function (app, index) {
                      return _c(
                        "el-menu-item",
                        {
                          key: app.identification_key,
                          attrs: { index: "sub_" + index },
                        },
                        [
                          _c(
                            "a",
                            {
                              staticClass: "acta-app-menu-item",
                              attrs: { href: app.link, target: "_blank" },
                            },
                            [_vm._v(" " + _vm._s(app.name))]
                          ),
                        ]
                      )
                    }),
                  ],
                  2
                )
              : _vm._e(),
            _c(
              "el-menu-item",
              {
                attrs: { index: "main_2" },
                on: {
                  click: function ($event) {
                    return _vm.logout()
                  },
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "logout-menu" },
                  [
                    _c("svg-icon", {
                      staticClass: "logout-icon",
                      attrs: { name: "logout" },
                    }),
                    _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                      _vm._v(" " + _vm._s(_vm.$t("route.logout")) + " "),
                    ]),
                  ],
                  1
                ),
              ]
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c("div", { staticClass: "navbar" }, [_c("app-breadcrumb")], 1),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }