import { IFile, IFolder } from './../models/file';
import { IResFile, IResFolder } from '../models/mock-models';
import EndPoints from '@/app/endpoints';
import request from '@/app/request';
import { IPayloadShare, IPayloadUnShare } from '@/models/share.Interface';

export const getObjects = (id: number) => {
  const URL = `${EndPoints.GET_OBJECT}/${(id || 0)}`;
  const axioRequest = request.get<{ data: IResFile | IResFolder | null }>(URL);
  return axioRequest
    .then(response => {
      if (response.status === 200 && response.data.data) {
        return response.data.data;
      } else {
        return null;
      }
    }).catch(() => {
      return null;
    });
};

export const renameObject = (name: string, target: IFolder | IFile) => {
  const id = target.id;
  const parentPath = target.path[target.path.length - 1]?.id || null;
  const URL = `${EndPoints.GET_OBJECT}/${id}`;
  const axiosRequest = request.patch(URL, {
    name: name,
    parent_folder: parentPath
  }
  );
  return axiosRequest;
};

export const moveObjectApi = (target: Array<IFolder | IFile>, parentId: number) => {
  const ids = target.map(x => x.id);
  const URL = `${EndPoints.GET_OBJECT}`;
  const axiosRequest = request.patch(URL, {
    ids,
    parent_folder: parentId
  }
  );
  return axiosRequest;
};

export const deleteObjectApi = (idList: number[]): Promise<boolean> => {
  const URL = `${EndPoints.GET_OBJECT}`;
  const axiosRequest = request.delete<{ data: IResFile | IResFolder | null }>(URL, {
    data: { ids: idList }
  });
  return axiosRequest
    .then(response => {
      if (response.status === 200) {
        return true;
      } else {
        return false;
      }
    }).catch(() => {
      return false;
    });
};

export const emptyTrashApi = (): Promise<boolean> => {
  const URL = `${EndPoints.EMPTY_TRASH}`;
  const axiosRequest = request.delete<any>(URL);
  return axiosRequest
    .then(response => {
      if (response.status === 200) {
        return true;
      } else {
        return false;
      }
    }).catch(() => {
      return false;
    });
};

export const permanentDeleteApi = (ids: number[]): Promise<boolean> => {
  const URL = `${EndPoints.PERMANENT_DELETE}`;
  const axiosRequest = request.delete<any>(URL, {
    data: { ids }
  });
  return axiosRequest
    .then(response => {
      if (response.status === 200) {
        return true;
      } else {
        return false;
      }
    }).catch(() => {
      return false;
    });
};


export const restoreObjectApi = (idList: number[], parentFolderId: number | null = null): Promise<boolean> => {
  const URL = `${EndPoints.TRASH_RESTORE}`;
  const payload: any = { ids: idList };
  if (parentFolderId !== null) {
    payload.parent_folder = parentFolderId;
  }
  const axiosRequest = request.post(URL, payload);
  return axiosRequest
    .then(response => {
      if (response.status === 200) {
        return true;
      } else {
        return false;
      }
    }).catch(() => {
      return false;
    });
};

export const createFolderObject = (formData: FormData) => {
  const URL = `${EndPoints.CREATE_FOLDER}`;
  const httpConfig = {
    headers: { 'Content-Type': 'multipart/form-data' },
    timeout: 0
  };
  const axiosRequest = request.post(URL, formData, httpConfig);
  return axiosRequest;
};

export const shareFile = (payload: IPayloadShare) => {
  const URL = `${EndPoints.MY_SHARE}`;
  const axiosRequest = request.post(URL, payload, { meta: { preventInterceptorErrorHandling: true } });
  return axiosRequest.then((response) => response.status).catch((error) => error.response.status);
};

export const unShareFile = (payload: IPayloadUnShare) => {
  const URL = `${EndPoints.MY_SHARE}/${payload.id}`;
  const axiosRequest = request.delete(URL, {
    data: payload,
    meta: { preventInterceptorErrorHandling: true }
  });
  return axiosRequest.then((response) => response.status).catch((error) => error.response.status);
};

export const unlinkSharedObjectApi = (id: number) => {
  const URL = `${EndPoints.UNLINK_SHARE}/${id}`;
  const axiosRequest = request.delete(URL, {
    meta: { preventInterceptorErrorHandling: true }
  });
  return axiosRequest.then((response) => response.status).catch((error) => error.response.status as number);
};

export const uploadFileChunk = (fileChunkData: FormData) => {
  const URL = `${EndPoints.CREATE_FILE}`;
  const httpConfig = {
    headers: { 'Content-Type': 'application/octet-stream' },
    timeout: 0,
  };
  const axiosRequest = request.post(URL, fileChunkData, httpConfig);
  return axiosRequest.then(response => {
    if (response?.status === 200) {
      return { success: true, res: response, message: response?.data?.message };
    } else {
      return { success: false, res: response, message: response?.data?.message };
    }
  }).catch((e) => {
    return { success: false, res: e?.response, message: e?.response?.data?.message };
  });
};

export const getFileDetails = (id: number, isShare = false) => {
  const URL = `${isShare ? EndPoints.SHARE : EndPoints.GET_OBJECT}/${id}`;
  const axiosRequest = request.get(URL, {
    meta: { preventInterceptorErrorHandling: true }
  });
  return axiosRequest.then((response) => response.data).catch(() => null);
};

export const checkRestorePathExist = (id: number) => {
  const URL = `${EndPoints.RESTORE_CHECK}/${id}`;
  const axiosRequest = request.get(URL, {
    meta: { preventInterceptorErrorHandling: true }
  });
  return axiosRequest
    .then((response) => response.status === 200 ? response.data.restore_location_available : false)
    .catch(() => false);
};
