<template>
  <div id="app">
    <router-view />
    <!-- <service-worker-update-popup />  -->
    <!--  REVIEW Disabled temporarily will be check the scope of usability -->
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import ServiceWorkerUpdatePopup from '@/pwa/components/ServiceWorkerUpdatePopup.vue';
import actaAppService from '@/services/acta-apps-service';
import { MessageType } from 'element-ui/types/message';
import i18n from '@/lang';

@Component({
  name: 'App',
  components: {
    ServiceWorkerUpdatePopup
  }
})
export default class ActaMyLib extends Vue {
  public networkStatus: any
  mounted() {
    actaAppService.init();
    window.addEventListener('online', this.updateOnlineStatus);
    window.addEventListener('offline', this.updateOnlineStatus);
  }

  updateOnlineStatus() {
    if (navigator.onLine) {
      this.networkStatus && this.networkStatus.close();
    } else {
      this.showNetworkStatusMessage('error', i18n.t('common.networkUnavailable').toString(), 0);
    }
  }

  showNetworkStatusMessage(type: MessageType, message: string, duration: number) {
    this.networkStatus && this.networkStatus.close();
    this.networkStatus = this.$message({
      type: type,
      message: message,
      duration: duration
    });
  }
}
</script>
