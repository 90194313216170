// Deprecated: When you are working move it to object-api
import { IResFile, IResFolder } from '../models/mock-models';
import request from '@/app/request';

export const getSharelist = (id: number) => {
  const axioRequest = request.get<{ data: IResFile | IResFolder | null }>('/shares/' + (id || 0));
  return axioRequest
    .then(response => {
      if (response.status === 200 && response.data.data) {
        return response.data.data;
      } else {
        return null;
      }
    }).catch((e) => {
      console.log('Error: getSharelist fetch', e);
      return null;
    });
};
