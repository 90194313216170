import i18n from '@/lang';
import axios, { AxiosError } from 'axios';
import { Message, MessageBox } from 'element-ui';
import EndPoints from '@/app/endpoints';

enum ToastType{
  warning = 'warning',
  error = 'error',
}

const baseURL = process.env.VUE_APP_API_BASE;

const messageShowingHttpErrors = [500,501,502,503,504,400,404,408,429];

let preventAuthPrompt = false;
const service = axios.create({
  baseURL: baseURL,
  withCredentials: true,
});

service.interceptors.request.use(
  (config) => {
    // if (token) { // REVIEW uncomment it if needed
    //   config.headers['X-Access-Token'] = ''
    // }
    return config;
  },
  error => {
    Promise.reject(error);
  }
);

service.interceptors.response.use(
  (response) => {
    return response;
  },
  (error: AxiosError | any) => {
    const url = (error.config?.url || '').replace(baseURL, '');
    if (url === EndPoints.CREATE_FILE) { //Prevent error handling for the CREATE_FILE here; this API may be triggered multiple times for chunk uploads and re-uploads
      return Promise.reject(error);
    }
    if (error.config?.meta?.preventInterceptorErrorHandling) {
      return Promise.reject(error);
    }
    const messageDisplayTime = parseInt(process.env.VUE_APP_MESSAGE_DISPLAY_TIME_IN_MILLISECONDS) || 400;
    if (error?.response?.status === 401) {
      if(preventAuthPrompt){ //Lock to avoid multiple error message due to parallel request.
          return;
      }
      preventAuthPrompt = true;
      const message = (error?.response?.data?.message === 'Force logout.' || error?.response?.data?.action === 'force_logout')
        ? i18n.t('common.settingsChanged').toString()
        : i18n.t('common.logoutMessage').toString();
      MessageBox.alert(message, {
        confirmButtonText: 'Log In',
        type: ToastType.warning,
        showClose: false
      }).then(() => {
        window.location.href =
          process.env.VUE_APP_LOGIN_REDIRECT_URL + '?redirect=' + encodeURIComponent(window.location.href);
      });
    } else if (error?.response?.status === 403 || error?.response?.data.message === 'Section access denied') {
      MessageBox.alert(error.response.data.message, {
        confirmButtonText: 'Ok',
        type: ToastType.error,
        showClose: false
      });
    } else if (messageShowingHttpErrors.includes(error?.response?.status)) {
      Message({
        message: i18n.t('common.httpError.'+error.response.status).toString(),
        type: ToastType.error,
        duration: messageDisplayTime,
      });
    } else if(error?.response?.data?.message !=''){
      Message({
        message: error.response.data.message,
        type: ToastType.error,
        duration: messageDisplayTime,
      });
    }
    else {
      Message({
        message: i18n.t('common.apiRequestFailed').toString(),
        type: ToastType.error,
        duration: messageDisplayTime,
      });
    }
    return Promise.reject(error);
  }
);

export default service;
