<template>
  <div class="new-object-dialog">
    <el-dialog
      :title="header" :visible.sync="showDialog" :modal-append-to-body="false" :close-on-click-modal="false"
      :before-close="closeDialog" :destroy-on-close="true"
    >
      <el-form ref="objectForm" :model="form" :rules="rules" @submit.prevent.native="createObject">
        <el-form-item v-if="typeProp === 'folder'" prop="folder_name">
          <el-input
            ref="folderName" v-model="form.folder_name" autocomplete="off" pattern="^[0-9a-zA-Z_\-. ]+$"
            :placeholder="$t('fileList.name')"
          />
        </el-form-item>
        <el-form-item v-if="typeProp === 'file'" prop="fileList" required>
          <el-upload
            ref="upload" v-model="form.fileList" class="upload-demo" :file-list="fileList" action
            :on-remove="resetFileContentAndClearFiles" :on-change="processAfterSelectsFile" :multiple="false"
            :auto-upload="false" drag
          >
            <i class="el-icon-upload" />
            <div class="el-upload__text">
              {{ $t('uploadPage.dropFileHere') }} <em>{{ $t('uploadPage.clickToSelect') }}</em>
            </div>
            <div slot="tip" class="el-upload__tip">
              {{ $t('uploadPage.uploadLimit', {size: maxSize}) }}
            </div>
          </el-upload>
        </el-form-item>
      </el-form>
      <p v-if="validationMessages.length">
        <ul class="validation-errors">
          <li v-for="(error, index) in validationMessages" :key="index">
            {{ error }}
          </li>
        </ul>
      </p>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeDialog()">{{ $t('labels.cancel') }}</el-button>
        <el-button
          v-loading.fullscreen.lock="loading" :disabled="!validateForm()" type="primary"
          @click="createObject()"
        >{{ $t('labels.create') }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script lang='ts'>
import Vue from 'vue';
import EventBus from '@/app/event-bus';
import { Config } from '@/app/config';
import { ElUpload } from 'element-ui/types/upload';
import objectService from '@/services/object-service';
import { getHumanReadableFileSize } from '@/utils/common';
import { EmitterKey } from '@/models/app';
import { UploadStatus } from '@/models/upload.models';

export default Vue.extend({
  name: 'NewObject',
  props: {
    openDialogProp: Boolean,
    typeProp: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      maxSize: getHumanReadableFileSize(Config.MAX_UPLOAD_FILE_SIZE_IN_BYTES),
      loading: false,
      fileContent: null as null | File,
      showDialog: false,
      fileList: [],
      form: { folder_name: '', fileList: '' },
      validationMessages: [],
      rules: {
        folder_name: [{ validator: objectService.validateName, trigger: 'change' }],
        fileList: [{ required: true, message: this.$t('formValidation.enterName'), trigger: 'change' }],
      },
    };
  },
  computed: {
 
    header(): string {
      if (this.typeProp === 'file') {
        return this.$t('labels.newFile') as string;
      } else {
        return this.$t('labels.newFolder') as string;
      }
    },
  },
  watch: {
    openDialogProp: function(oldVal) {
      this.showDialog = oldVal;
      this.$nextTick(() => {
        this.$refs.folderName && (this.$refs.folderName as HTMLInputElement).focus();
      });
    },
  },
  mounted() {
    EventBus.$on(EmitterKey.FILE_UPLOAD_COMPLETED, this.fileUploadCompleted);
  },
  beforeDestroy() {
    EventBus.$off(EmitterKey.FILE_UPLOAD_COMPLETED, this.fileUploadCompleted);
  },
  methods: {
    fileUploadCompleted(payload: any) {
      const name = this.trimStringAndAddEllipsis(payload.name, 50);
      const errorMessage = this.trimStringAndAddEllipsis(payload.errorMessage, 200);
      if (payload.status === UploadStatus.DONE) {
        this.showAlert('success', this.$t('dialog.create.fileUploadSuccessMessage', { name }));
      }  if (payload.status === UploadStatus.FAILED) {
        if (payload.errorMessage === '') {
          this.showAlert('error', this.$t('dialog.create.fileUploadGenericFailedMessage', { name }));
        } else {
          this.showAlert('error', this.$t('dialog.create.fileUploadSpecificFailedMessage', { errorMessage }));
        }
      }
    },
    checkFileSize(size: number) {
      return size > Config.MAX_UPLOAD_FILE_SIZE_IN_BYTES;
    },
    trimStringAndAddEllipsis(input: string, limit: number) {
      let str = input.toString();
      if (str.length > limit) {
        return str.substring(0, limit - 3) + '...';
      }
      return str;
    },
    closeDialog: function() {
      this.$emit('close-new-object-dialog');
      this.resetForm('objectForm');
      this.resetFileContentAndClearFiles();
      this.loadValidationMessages({});
    },
    validateForm() {
      if (this.typeProp === 'file' && this.fileContent) {
        const processingItems =  objectService.uploadQueueList.filter(que => 
        que.status !== UploadStatus.DONE &&
        que.status !== UploadStatus.FAILED &&
        que.status !== UploadStatus.CANCELED 
      );
        return processingItems.length === 0 ;
      }
      if (this.typeProp === 'folder' && this.form.folder_name) {
        return objectService.validateObjectName(this.form.folder_name);
      }
      return false;
    },

    createObject() {
      if (this.validateForm()) {
        this.loading = true;
        const objectType = this.typeProp.toLowerCase();

        if (objectType === 'file') {
          objectService.createFile(this.fileContent as File);
          this.loading = false;
          this.closeDialog();
          this.resetFileContentAndClearFiles();
        } else {
          objectService
            .createFolder(this.form.folder_name)
            .then(() => {
              this.showAlert('success', this.$t('dialog.create.folderCreatedMessage'));
              this.closeDialog();
            })
            .catch((error) => {
              this.loadValidationMessages(error.response.data.errors);
            })
            .finally(() => {
              this.resetFileContentAndClearFiles();
              this.loading = false;
              objectService.refreshFolder();
            });
        }
      }
    },
    loadValidationMessages(messages: object) {
      this.validationMessages = [];
      for (var fieldName in messages) {
        var errors = messages[fieldName as keyof object];
        this.validationMessages.push(errors[0]);
      }
    },
    processAfterSelectsFile(file: any, fileList: any) {
      if (fileList.length > 1) {
        fileList.splice(0, 1);
      }
      const self = this;
      const maxFileSizeError = self.$t('dialog.create.fileMaxSizeError', { max: Config.MAX_UPLOAD_FILE_SIZE_IN_BYTES });
      self.fileContent = file.raw;
      if (file && this.checkFileSize(file.raw.size)) {
        this.resetFileContentAndClearFiles();
        this.loadValidationMessages({ file: [maxFileSizeError] });
      } else {
        this.validationMessages = [];
      }
    },
    resetFileContentAndClearFiles() {
      this.fileContent = null;
      this.$refs.upload && (this.$refs.upload as ElUpload).clearFiles();
    },
    showAlert(type: any, message: any) {
      this.$message({
        showClose: true,
        message: message,
        type: type,
      });
    },
    resetForm(formName: string) {
      (this.$refs[formName] as any).resetFields();
    },
  },
});
</script>
<style lang="scss" scoped>
:deep(.el-dialog) {
  max-width: 30rem;
  width: 95%;

  .validation-errors {
    color: #ff5252;
    list-style: none;
    padding-left: 0px;
  }

  .el-upload {
    width: 100%;
  }

  .el-upload-dragger {
    width: 100% !important;
    height: 120px;

    .el-icon-upload {
      font-size: 40px;
      line-height: 0px;
      margin: 30px 0 16px;
    }
  }
}
</style>