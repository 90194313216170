var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { ref: "menuEl", staticClass: "context-menu" }, [
    _c(
      "ul",
      { staticClass: "custom-menu" },
      _vm._l(_vm.menuItems, function (item) {
        return _c(
          "li",
          {
            key: item.key,
            on: {
              click: function ($event) {
                return _vm.selected(item)
              },
            },
          },
          [
            _c("i", { staticClass: "icons", class: item.icon }),
            _vm._v(" " + _vm._s(_vm.$t("contextMenu." + item.i18nLabel)) + " "),
          ]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }