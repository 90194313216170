var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "app-wrapper", class: _vm.classObj },
    [
      _vm.classObj.mobile && _vm.sidebar.opened
        ? _c("div", {
            staticClass: "drawer-bg",
            on: { click: _vm.handleClickOutside },
          })
        : _vm._e(),
      _c("app-sidebar", { staticClass: "sidebar-container" }),
      _c(
        "div",
        {
          staticClass: "main-container",
          class: { hasTagsView: _vm.showTagsView },
        },
        [
          _c(
            "div",
            { class: { "fixed-header": _vm.fixedHeader } },
            [_c("app-head")],
            1
          ),
          _c("app-main"),
          _c("app-context-menu"),
        ],
        1
      ),
      _c("app-footer"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }