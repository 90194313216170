<template>
  <footer class="footerbar" :class="{'on-upload': showPanel}">
    <el-card v-if="showPanel" shadow="hover" class="upload-box" :body-style="cardBodyStyleProp">
      <div class="upload-layout" :class="{'upload-layout-jfs': activeStatus === enumUploadUIStatus.UPLOAD_COMPLETE}">
        <div class="column-item col-progress">
          <el-progress
            v-if="activeStatus === enumUploadUIStatus.UPLOAD_PROGRESS"
            class="spinner"
            :class="{process: progress === PROGRESS_MAXIMUM}"
            type="circle"
            :percentage="progress"
          />
          <svg-icon v-else class="icon-router" index="/upload" name="cloud_upload" />
        </div>
        <div class="column-item col-label">
          <span v-if="activeStatus === enumUploadUIStatus.UPLOAD_PROGRESS" class="lable-text">
            {{ $t('uploadPage.uploading') }} {{ fileName }}
          </span>
          <span v-if="activeStatus === enumUploadUIStatus.UPLOAD_PAUSE" class="lable-text">
            {{ $t('uploadPage.uploadPaused') }}
          </span>
          <span v-if="activeStatus === enumUploadUIStatus.UPLOAD_COMPLETE" class="lable-text">
            {{ $t('uploadPage.uploadCompleted') }}
          </span>
        </div>
        <div v-if="activeStatus !== enumUploadUIStatus.UPLOAD_COMPLETE" class="column-item col-action">
          <el-tooltip
            v-if="activeStatus === enumUploadUIStatus.UPLOAD_PROGRESS && progress < PROGRESS_MAXIMUM"
            popper-class="capitalize"
            effect="dark"
            :content="$t('uploadPage.pause')"
            placement="top-start"
          >
            <el-button type="warning" icon="el-icon-video-pause" size="mini" plain @click="pause()" />
          </el-tooltip>
          <el-tooltip
            v-if="activeStatus === enumUploadUIStatus.UPLOAD_PAUSE"
            popper-class="capitalize"
            effect="dark"
            :content="$t('uploadPage.resume')"
            placement="top-start"
          >
            <el-button type="success" icon="el-icon-video-play" size="mini" plain @click="resume()" />
          </el-tooltip>
        </div>
        <div
          v-if="activeStatus === enumUploadUIStatus.UPLOAD_PROGRESS && progress < PROGRESS_MAXIMUM"
          class="column-item col-action"
        >
          <el-tooltip popper-class="capitalize" effect="dark" :content="$t('labels.cancel')" placement="top-start">
            <el-button type="danger" icon="el-icon-close" size="mini" plain @click="cancel()" />
          </el-tooltip>
        </div>
      </div>
    </el-card>
  </footer>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import { UploadQueueItem, UploadStatus, UploadUIStatus } from '@/models/upload.models';
import EventBus from '@/app/event-bus';
import objectService from '@/services/object-service';
import { EmitterKey } from '@/models/app';
import { promiseDelay } from '@/utils/common';

@Component({
  name: 'AppFooter',
})
export default class extends Vue {
  public PROGRESS_MAXIMUM = 100;
  public PROGRESS_MIN = 0;
  public enumUploadUIStatus = UploadUIStatus;
  public closeDelay = 800;
  public cardBodyStyleProp = { padding: '8px' };
  public showPanel = false;
  public fileName = '';
  public progress = this.PROGRESS_MIN;
  public activeStatus = UploadUIStatus.UPLOAD_COMPLETE;

  mounted() {
    EventBus.$on(EmitterKey.FILE_UPLOAD_STARTED, this.downloadStart);
    EventBus.$on(EmitterKey.FILE_UPLOAD_PROGRESS, this.uploaderEvent);
    EventBus.$on(EmitterKey.FILE_UPLOAD_INTERRUPTED, this.uploaderEvent);
    EventBus.$on(EmitterKey.FILE_UPLOAD_ACTION, this.uploaderEvent);
    EventBus.$on(EmitterKey.FILE_UPLOAD_COMPLETED, this.downloadEnd);
  }
  beforeDestroy() {
    EventBus.$on(EmitterKey.FILE_UPLOAD_STARTED, this.downloadStart);
    EventBus.$on(EmitterKey.FILE_UPLOAD_PROGRESS, this.uploaderEvent);
    EventBus.$on(EmitterKey.FILE_UPLOAD_INTERRUPTED, this.uploaderEvent);
    EventBus.$on(EmitterKey.FILE_UPLOAD_ACTION, this.uploaderEvent);
    EventBus.$on(EmitterKey.FILE_UPLOAD_COMPLETED, this.downloadEnd);
  }

  downloadStart() {
    this.activeStatus = UploadUIStatus.UPLOAD_PROGRESS;
    this.showPanel = true;
    this.uploaderEvent();
    this.$forceUpdate();
  }

  async downloadEnd(payload: any) {
    this.uploaderEvent();
    this.activeStatus = UploadUIStatus.UPLOAD_COMPLETE;
    this.progress = this.PROGRESS_MAXIMUM;
    if (payload.status === UploadStatus.DONE) {
      await promiseDelay(this.closeDelay);
    }
    this.closePanel();
  }

  async closePanel() {
    this.progress = this.PROGRESS_MIN;
    this.showPanel = false;
    this.fileName = '';
    this.$forceUpdate();
  }

  uploaderEvent() {
    let item = this.getCurrentActiveItem();
    if (item) {
      this.fileName = item.name;
      this.progress = this.getProgress(item);
      this.setUIStatus(item);
    }
    this.$forceUpdate();
  }

  private setUIStatus(item: UploadQueueItem) {
    if (
      item.status === UploadStatus.AWAITING ||
      item.status === UploadStatus.DONE ||
      item.status === UploadStatus.CANCELED
    ) {
      this.activeStatus = UploadUIStatus.UPLOAD_COMPLETE;
    } else if (item.status === UploadStatus.IN_PROGRESS || item.status === UploadStatus.RESUME) {
      this.activeStatus = UploadUIStatus.UPLOAD_PROGRESS;
    } else if (item.status === UploadStatus.PAUSE) {
      this.activeStatus = UploadUIStatus.UPLOAD_PAUSE;
    }
  }

  private getProgress(item: UploadQueueItem) {
    const totalChunk = item.chunkList.length;
    let completed = item.chunkList.filter((it) => it.chunkStatus === UploadStatus.DONE).length;
    completed = Math.min(completed + 1, totalChunk);
    return Math.round((completed / totalChunk) * 100);
  }

  cancel() {
    let item = this.getCurrentActiveItem();
    if (item) {
      objectService.cancelUpload(item.fileId);
    }
    this.activeStatus = UploadUIStatus.UPLOAD_COMPLETE;
    this.closePanel();
  }

  resume() {
    let item = this.getCurrentActiveItem();
    if (item) {
      objectService.resumeUpload(item.fileId);
    }
  }

  pause() {
    let item = this.getCurrentActiveItem();
    if (item) {
      objectService.pauseUpload(item.fileId);
    }
  }

  getCurrentActiveItem() {
    return objectService.uploadQueueList.find(
      (item) =>
        item.status === UploadStatus.IN_PROGRESS ||
        item.status === UploadStatus.RESUME ||
        item.status === UploadStatus.PAUSE
    );
  }

  showAlert(type: any, message: any) {
    this.$message({
      showClose: true,
      message: message,
      type: type,
    });
  }
}
</script>

<style lang="scss" scoped>
.el-popover {
  position: fixed;
}

.footerbar {
  position: relative;
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &.on-upload {
    height: 60px;
  }
  .upload-layout {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    min-height: 32px;

    &.upload-layout-jfs {
      justify-content: flex-start !important;
    }

    .column-item {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      margin: 0px 4px;
      
      &.col-label {
        justify-content: flex-start;
        min-width: 210px;
        .lable-text {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          width: 100%;
          font-size: 13px;
        }
      }

      &.col-action {
        max-width: 34px;
        .el-button {
          font-size: 20px;
          padding: 3px;
        }
      }

      &.col-progress {
        max-width: 50px;
      }
    }
  }
  .upload-box {
    bottom: 10px;
    right: 12px;
    position: fixed;
    width: 350px;
    z-index: 2030;

    &::v-deep {
      .spinner {
        height: 32px;
        width: 32px;

        .el-progress-circle {
          height: 32px !important;
          width: 32px !important;
          .el-progress-circle__path {
            stroke-width: 6px;
          }
        }

        .el-progress__text {
          font-size: 10px !important;
        }

        &.process {
          .el-progress__text {
            display: none;
          }

          .el-progress-circle__path {
            stroke-dasharray: 50% !important;
            stroke-dashoffset: 0px !important;
            transform-box: fill-box;
            transform-origin: center;
            animation: spin 1s ease-in-out infinite;
          }
        }
      }
    }
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
</style>
