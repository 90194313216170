export default {
  route: {
    trash: 'Trash',
    upload: 'Upload',
    browse: 'Browse',
    shared: 'Shared',
    new: 'New',
    createFolder: 'Create Folder',
    uploadFile: 'Upload File',
    home: 'MyLibrary',
    logout: 'Logout'
  },
  logout: {
    title: 'Logout',
    message: 'Are you sure you want to logout? This will also logout you from the other associated sites and redirect you to the login page afterward.'
  },
  preview: {
    title: 'Preview',
    notAvailable: 'Preview not available',
    toolbar: {
      rotateLeft: 'Rotate left',
      rotateRight: 'Rotate right',
      zoomIn: 'Zoom in',
      zoomOut: 'Zoom out',
      toggleFitWidth: 'Toggle Fit width',
      fitImage: 'Fit Image',
      actualSize: 'Actual Size'
    },
    previewError: {
      unSupported: 'Unable to open this file because it\'s in an unsupported format'
    },
  },
  fileList: {
    name: 'Name',
    shareColumn: 'Share',
    lastModified: 'Last Modified',
    fileSize: 'File Size',
    folderSize: 'Folder Size',
    deletedAt: 'Deleted At',
    sharedAt: 'Shared At',
    sharedBy: 'Shared By',
    onlyMe: 'Only Me',
    sharePopover: {
      sharedAt: 'Shared At',
      sharedTo: 'Shared To',
      opener: 'Shared',
      action: 'Action',
      sharedWith: 'Shared with',
      notfound: 'The shared list is empty',
      edit: 'Edit',
    },
    created_by: 'Created By',
    unavailable: 'Unavailable',
    source: 'Source',
    action: 'Action'
  },
  page: {
    SharePage: {
      heading: 'Shared List',
      unlinkShareAction: {
        promptMessage: 'Are you sure you want to remove access to the {fileTypeAndName}?',
        promptTitle: 'Remove shared item',
        success: 'Successfully removed the access to {fileTypeAndName}',
        failed: 'Unable to remove the access. Please try after sometime',
      },
    },
    trashPage: {
      emptyTrash: {
        btnText: 'Empty Trash',
        prompt: {
          btnYes: 'Yes, Continue',
          title: 'Empty Trash',
          message: 'Are you sure you want to delete all items in the trash? This action cannot be undone \n Type "{verification}" in the textbox to continue',
          verificationMisMatch: 'The entered confirmation text does not match. Please try again.',
        },
        response: {
          success: 'All files in the trash have been deleted',
          error: 'Unable to clear the trash. Please try after sometime',
        }
      },
      permanentDelete: {
        actionButton: 'Permanent Delete Selected',
        prompt: {
          title: 'Permanent Delete',
          messageSingle: 'Are you sure you want to permanently delete this {fileTypeAndName}? This action cannot be undone \n Type "{verification}" in the textbox to continue',
          messageMultiple: 'Are you sure you want to permanently delete the selected item(s)? This action cannot be undone \n Type "{verification}" in the textbox to continue',
          verificationMisMatch: 'The entered confirmation text does not match. Please try again',
          btnYes: 'Yes, Continue',
        },
        response: {
          failedSingle: 'Unable to permanently delete the {fileTypeAndName}. Please try after sometime',
          failedMultiple: 'Unable to permanently delete the selected items. Please try after sometime',
          successSingle: 'The {fileTypeAndName} have been permanently deleted',
          successMultiple: 'The selected items have been permanently deleted',
        }
      },
      restoreSelected: 'Restore Selected',
    },
    browsePage: {
      deleteSelected: 'Delete selected',
      deleteSelectedTitle: 'Delete selected Items',
      deleteSelectedPrompt: 'Are you sure you want to delete selected item(s)?',
      deleteSelectedSuccess: 'The selected item(s) have been deleted',
      deleteSelectedError: 'Unable to delete the selected item(s). Please try after sometime',
      moveSelected: 'Move selected',
    }
  },
  contextMenu: {
    download: 'Download',
    open: 'Open',
    rename: 'Rename',
    delete: 'Delete',
    details: 'Details',
    share: 'Share',
    move: 'Move',
    restore: 'Restore',
    uploadFile: 'Upload File',
    createFolder: 'Create Folder',
    permanentDelete: 'Permanent Delete',
    unlinkShare: 'Remove',
  },
  dialog: {
    common: {
      folder: 'folder',
      file: 'file',
      items: 'item(s)',
      selectedItems: 'selected item(s)',
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    },
    rename: {
      title: 'Rename',
      message: 'Please enter the new {type} name',
      confirmButtonText: 'Rename',
      cancelButtonText: 'Cancel',
      validation: 'Invalid {type} name',
      successMessage: '{fileTypeAndName} is successfully renamed to {newName}',
      errorMessage: 'Failed to rename, please try after some time'
    },
    share: {
      sharedWith: 'Shared with',
      shareResponse: {
        success: 'The {fileTypeAndName} shared successfully',
        error: 'Failed to share the {fileTypeAndName}, Can only be shared with users under the same organisation',
        wrongEmail: 'The provided email address is invalid or unregistered',
        alreadyShared: 'The {fileTypeAndName} already shared with this user',
      },
      unShareResponse: {
        success: 'The {fileTypeAndName} unshared successfully',
        error: 'Failed to unshare the {fileTypeAndName}, please try again later',
      },
      unSharePrompt: {
        title: 'Unshare access',
        message: 'Are you sure you want to revoke access for \'{email}\'?',
      },
      placeholder: 'Enter email address to share',
      unShare: 'Unshare',
      notifyUser: 'Notify user'
    },
    move: {
      title: 'Move {type}',
      heading: 'Select a target folder for \'{name}\'',
      filterPlaceHolder: 'Filter from list',
      foldersNotFound: 'Could not find folders',
      cancelButtonText: 'Cancel',
      moveButton: 'Move',
      moveAlert: {
        message: 'Please select a folder',
        title: 'Move to',
        ok: 'OK',
      },
      successMessage: 'The {type} \'{target}\' has been successfully moved to the folder \'{dest}\'',
      errorMessage: 'Failed to move {type} \'{target}\'',
      sameFolderWarning: 'Already in the same folder',
      rootFolderName: 'MyLibrary',
    },
    delete: {
      title: 'Delete',
      message: 'Do you want to delete this {fileTypeAndName}?',
      successMessage: 'Successfully deleted the {fileTypeAndName}',
      errorMessage: 'Failed to delete, please try after some time',
      sharedUserMessage: 'The {fileTypeAndName} will be deleted from all shared users as well.'
    },
    create: {
      fileUploadSuccessMessage: 'The file "{name}" has been successfully uploaded.',
      fileUploadSpecificFailedMessage: 'Upload failed, {errorMessage}',
      fileUploadGenericFailedMessage: 'Failed to upload the file "{name}". Please try again later.',
      folderCreatedMessage: 'Folder created successfully',
      fileMaxSizeError: 'The selected file must not be greater than {max} MB.'
    },
    restore: {
      common: {
        errorMessage: 'Failed to restore, please try after some time',
      },
      single: {
        title: 'Restore',
        message: 'Do you want to restore this {objectTypeAndName}?',
        checkingRestorePath:'Checking for previous location',
        restorePathExist: 'The item will be restored to its original location',
        restorePathNotExist: 'Original location does not exist, the item will be restored to the root folder',
        sharedUserMessage: 'The shared users will also get access to this item.',
        successMessage: 'The {objectTypeAndName} is successfully restored',
      },
      multiple: {
        title: 'Restore selected item(s)',
        message: 'Do you want to restore the selected item(s)?',
        restoreTip: 'The selected item(s) will be restored to their original location, If the original location no longer exists or they will be restored to the root folder.',
        sharedUserMessage: 'The shared users will also get access to the item(s).',
        successMessageMultiple: 'The selected item(s) are successfully restored',
      }
    },
  },
  uploadPage: {
    dragAndDropToUploadContent: 'Drag and drop to upload content!',
    exitConfirmation: 'Refreshing or leaving this page will cancel the current file uploads. Are you sure you want to exit this page?',
    uploadLimit: 'Maximum upload file size: {size}',
    dropFileHere: 'Drop file here or',
    clickToSelect: 'click to select',
    uploadProgress: 'Uploading is in progress',
    uploadCompleted: 'Upload Completed',
    uploadFailed: 'Uploading failed, please try after some time',
    uploading: 'Uploading',
    completed: 'Completed',
    failed: 'Failed',
    pause: 'Pause',
    resume: 'Resume',
    uploadPaused:'Upload Paused',
  },
  SharedPage: {
    title: 'Shared List'
  },
  common: {
    noActionsFound: 'No actions are available',
    logoutMessage: "You haven't logged in to ActaPublica or your session has expired. Log in again to continue.",
    settingsChanged: "We've recently made some changes to your access to the service. You've been logged out. We request you to login again so that those changes will take effect immediately.",
    apiRequestFailed: 'The MyLibrary server is experiencing some issues. Please try again later.',
    noAccess: 'You don\'t have access to the MyLibrary section',
    accessRequired: 'MyLibrary access required',
    requestAccess: 'Request Access',
    networkUnavailable: 'You are offline, Please check your network connection',
    httpError: {
      500: 'Internal Server Error: MyLibrary encountered an unexpected condition that prevented it from fulfilling the request',
      501: 'Not Implemented: The MyLibrary server does not support the functionality required to fulfill the request',
      502: 'Bad Gateway: Unable to reach the MyLibrary server',
      503: 'Service Unavailable: The MyLibrary service is currently unable to handle the request due to a temporary overload or maintenance of the server',
      504: 'Gateway Timeout: The MyLibrary server, did not receive a timely response from the upstream service',
      400: 'Bad Request: The server cannot process the request due to a client error',
      404: 'Not Found: The server cannot find the requested file/folder',
      408: 'Request Timeout: The MyLibrary server timed out waiting for the request',
      429: 'Too Many Requests: The user has sent too many requests in a given amount of time',
    }
  },
  labels: {
    cancel: 'Cancel',
    create: 'Create',
    share: 'Share',
    open: 'Open',
    rename: 'Rename',
    delete: 'Delete',
    details: 'Details',
    move: 'Move',
    email: 'Email',
    chooseFile: 'Choose a file',
    newFile: 'New file',
    newFolder: 'New folder',
    createNew: 'Create New',
    file: 'File',
    folder: 'Folder',
    back: 'Back',
    folders: 'Folders',
    files: 'Files'
  },
  titles: {
    shareDialog: 'Share with people',
    noSearchResult: 'No results found'
  },
  formValidation: {
    incorrectEmail: 'Please enter a valid email address',
    enterName: 'Please enter a name',
    enterValidName: 'Please enter valid name'
  },
  folderPicker:{
    input:{
      placeholder:'Folder not selected',
      buttonText:'Select a folder',
    },
    selectFolder: 'Choose the folder',
    placeholder: 'Filter folders',
    notFound: 'No folders found',
    button:{
      select:'Select',
      cancel:'Cancel',
    }
  }
};
