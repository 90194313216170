var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { class: { "has-logo": _vm.showLogo } },
    [
      _vm.showLogo
        ? _c(
            "div",
            {
              staticClass: "sidebar-logo-container",
              class: { collapse: _vm.isCollapse },
            },
            [
              _c(
                "transition",
                { attrs: { name: "sidebarLogoFade" } },
                [
                  _vm.isCollapse
                    ? _c(
                        "router-link",
                        {
                          key: "collapse",
                          staticClass: "sidebar-logo-link",
                          attrs: { to: "/" },
                        },
                        [
                          _c("img", {
                            staticClass: "sidebar-logo",
                            attrs: {
                              src: require("@/assets/images/acta-icon.svg"),
                            },
                          }),
                        ]
                      )
                    : _c(
                        "router-link",
                        {
                          key: "expand",
                          staticClass: "sidebar-logo-link",
                          attrs: { to: "/" },
                        },
                        [
                          _c("img", {
                            staticClass: "sidebar-logo",
                            attrs: {
                              src: require("@/assets/images/acta-icon.svg"),
                            },
                          }),
                          _c("h1", { staticClass: "sidebar-title" }, [
                            _vm._v(" Acta Publica "),
                          ]),
                        ]
                      ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c("new-object", {
        attrs: {
          "open-dialog-prop": _vm.dialogVisible,
          "type-prop": _vm.newObjectType,
        },
        on: {
          "close-new-object-dialog": function ($event) {
            return _vm.closeDialog()
          },
        },
      }),
      _c(
        "el-scrollbar",
        { attrs: { "wrap-class": "scrollbar-wrapper " } },
        [
          _c(
            "el-menu",
            {
              attrs: {
                collapse: _vm.isCollapse,
                "default-active": _vm.defaultActive,
                "active-text-color": _vm.menuActiveTextColor,
                "unique-opened": false,
                "collapse-transition": false,
                router: true,
                "menu-trigger": "hover",
                mode: "vertical",
              },
            },
            [
              _c(
                "el-menu-item",
                { attrs: { index: "/browse", router: true } },
                [
                  _c("svg-icon", {
                    staticClass: "icon-router",
                    attrs: { name: "documentation" },
                  }),
                  _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                    _vm._v(_vm._s(_vm.$t("route.browse"))),
                  ]),
                ],
                1
              ),
              _c(
                "el-menu-item",
                { attrs: { index: "/shared" } },
                [
                  _c("svg-icon", {
                    staticClass: "icon-router",
                    attrs: { name: "share" },
                  }),
                  _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                    _vm._v(_vm._s(_vm.$t("route.shared"))),
                  ]),
                ],
                1
              ),
              _c(
                "el-submenu",
                {
                  attrs: {
                    disabled: _vm.$route.name !== "browse",
                    index: "/upload",
                  },
                },
                [
                  _c(
                    "template",
                    { slot: "title" },
                    [
                      _c("svg-icon", {
                        staticClass: "icon-router",
                        attrs: { name: "add_circle" },
                      }),
                      _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                        _vm._v(_vm._s(_vm.$t("route.new"))),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "el-menu-item",
                    {
                      attrs: { disabled: _vm.$route.name !== "browse" },
                      on: {
                        click: function ($event) {
                          return _vm.openNewObjectDialog("file")
                        },
                      },
                    },
                    [
                      _c("svg-icon", {
                        staticClass: "icon-router",
                        attrs: { name: "cloud_upload" },
                      }),
                      _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                        _vm._v(_vm._s(_vm.$t("route.uploadFile"))),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "el-menu-item",
                    {
                      attrs: { disabled: _vm.$route.name !== "browse" },
                      on: {
                        click: function ($event) {
                          return _vm.openNewObjectDialog("folder")
                        },
                      },
                    },
                    [
                      _c("svg-icon", {
                        staticClass: "icon-router",
                        attrs: { index: "/upload", name: "cloud_upload" },
                      }),
                      _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                        _vm._v(_vm._s(_vm.$t("route.createFolder"))),
                      ]),
                    ],
                    1
                  ),
                ],
                2
              ),
              _c(
                "el-menu-item",
                { attrs: { index: "/trash" } },
                [
                  _c("svg-icon", {
                    staticClass: "icon-router",
                    attrs: { name: "delete" },
                  }),
                  _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                    _vm._v(_vm._s(_vm.$t("route.trash"))),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }