var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            "before-close": _vm.beforeClose,
            "close-on-click-modal": false,
            "close-on-press-escape": false,
            title: _vm.title,
            visible: _vm.moveDialogVisible,
            "append-to-body": true,
            width: "30%",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.moveDialogVisible = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.initialLoading,
                  expression: "initialLoading",
                },
              ],
            },
            [
              _c("h4", [_vm._v(_vm._s(_vm.heading))]),
              _c("el-input", {
                attrs: { placeholder: _vm.$t("dialog.move.filterPlaceHolder") },
                model: {
                  value: _vm.filterText,
                  callback: function ($$v) {
                    _vm.filterText = $$v
                  },
                  expression: "filterText",
                },
              }),
              _vm.moveDialogVisible
                ? _c("el-tree", {
                    ref: "tree",
                    staticClass: "filter-tree",
                    attrs: {
                      data: _vm.treeData,
                      props: _vm.defaultProps,
                      load: _vm.loadNode,
                      "empty-text": _vm.$t("dialog.move.foldersNotFound"),
                      "expand-on-click-node": true,
                      "highlight-current": true,
                      "icon-class": "el-icon-folder",
                      "filter-node-method": _vm.filterNode,
                      lazy: "",
                    },
                    on: { "node-click": _vm.nodeSelected },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function ({ node, data }) {
                            return _c(
                              "span",
                              {
                                staticClass: "el-tree-node__label",
                                class: { disabled: !_vm.isTargetAllowed(data) },
                              },
                              [
                                _vm._v(" " + _vm._s(node.label) + " "),
                                !_vm.isTargetAllowed(data)
                                  ? _c(
                                      "el-tooltip",
                                      {
                                        staticClass: "info-icon",
                                        attrs: {
                                          effect: "dark",
                                          content: _vm.$t(
                                            "dialog.move.sameFolderWarning"
                                          ),
                                          placement: "top-start",
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "el-icon-question",
                                        }),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            )
                          },
                        },
                      ],
                      null,
                      false,
                      3949680594
                    ),
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.close()
                    },
                  },
                },
                [
                  _vm._v(
                    " " + _vm._s(_vm.$t("dialog.move.cancelButtonText")) + " "
                  ),
                ]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    disabled: !_vm.isTargetAllowed(
                      _vm.selectedDestinationFolder
                    ),
                  },
                  on: {
                    click: function ($event) {
                      return _vm.movetoSelectedFolder()
                    },
                  },
                },
                [_vm._v(" " + _vm._s(_vm.$t("dialog.move.moveButton")) + " ")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }